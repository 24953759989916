<template>
  <v-app>
    <div class="text-center" v-if="loading === true">
      <v-progress-circular :size="70" :width="10" color="blue" indeterminate />
      <br />
      お客様の情報を確認中です。<br />
      処理が完了するまでお待ちください。
    </div>

    <v-alert
      v-model="alert"
      close-text="Close Alert"
      color="orange accent-4"
      dark
      dismissible
      class="ma-5"
    >
      {{ error_message }}
    </v-alert>

    <div class="text-center ma-5">
      <h2 class="display-1">診察券・患者様番号を登録</h2>
    </div>
    <div class="ma-5" v-if="clinic">
      クリニックの診察券に記載されている患者様番号（{{
        clinic.patient_number
      }}桁の数字）とお名前を登録してください。
      <v-img
        class="ma-5"
        height="200"
        :src="image_url"
        aspect-ratio="1.0"
        contain
      ></v-img>
    </div>
    <v-card width="95%" class="mx-auto" v-if="loading === false">
      <v-form class="ma-5">
        <div v-if="clinic">{{ clinic.name }}</div>
        <v-text-field
          prepend-icon="mdi-numeric"
          label="患者番号 No. "
          outlined
          clearable
          v-model="patientCode"
        />
        <div class="signin">
          <v-text-field
            prepend-icon="mdi-account"
            label="お名前 Name"
            outlined
            clearable
            v-model="user_name"
          />
          <v-btn
            class="info"
            id="submit"
            @click="addPatientCode"
            v-if="
              user_name != '' && patientCode.length >= clinic.patient_number
            "
            >登録</v-btn
          >
          <v-btn
            depressed
            disabled
            v-if="user_name == '' && patientCode.length < clinic.patient_number"
            >登録</v-btn
          >
        </div>
      </v-form>
    </v-card>
  </v-app>
</template>

<script>
import { Firebase } from "../config/firebase";
//import patient from "../mixins/patient";
export default {
  name: "PatientRegster",

  data() {
    return {
      loading: true,
      patientCode: "",
      user_name: "",
      welcome: null,
      alert: false,
      error_message: "",
      firestorage: null,
      storageRef: null,
      image_url: "",
      user_clinic: null,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    user_id() {
      return this.$store.getters.user.uid;
    },
    clinic() {
      if (this.id === "") {
        return undefined;
      } else {
        return this.$store.state.clinicModule.data[this.id];
      }
    },
    user() {
      return this.$store.state.userModule.data[this.user_id];
    },
  },

  created: async function () {
    try {
      this.fetchClinics();
    } catch (e) {
      this.error = true;
      console.log(e);
    } finally {
      this.loading = false;
    }
    this.loading = false;
    try {
      await this.fetchUsers();
    } catch (e) {
      this.error = true;
      console.log(e);
    } finally {
      this.loading = false;
    }
    this.loading = false;
  },
  methods: {
    fetchUsers: async function () {
      await this.$store.dispatch(
        "userModule/fetchById",
        this.$store.getters.user.uid
      );
    },
    fetchClinics: async function () {
      await this.$store.commit("clinicModule/RESET_VUEX_EASY_FIRESTORE_STATE");
      try {
        await this.$store.dispatch("clinicModule/fetchById", this.id);
        console.log("clinic id", this.clinic);
      } catch (e) {
        this.error = true;
        this.$router.push({
          name: "error",
          params: { message: "表示できません。" },
        });
      } finally {
        this.loading = false;
      }
      this.image_url = "/images/cards/" + this.clinic.id + ".png";
    },
    async addPatientCode() {
      this.alert = false;
      this.loading = true;
      // Check already registered
      // an array of arrays

      await Firebase.firestore()
        .collection("Patients")
        .where("clinic_id", "==", this.clinic.id)
        .where("patientNumber", "==", this.patientCode)
        .where("user_id", "==", this.user_id)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(() => {
            this.error_message = "すでに登録済みです。";
            console.log("results: ", "すでに登録済みです。");
            this.loading = false;
            this.alert = true;
            this.$router.push({
              name: "priscription_list",
              params: { id: this.id },
            });
          });
        })
        .catch((error) => {
          throw new Error(
            Date().toLocaleString("ja") +
              " [erro code: 121] getGetwayList: gateway/list error" +
              error
          );
        });

      // Search patient document
      const patientRef = Firebase.firestore().collection("Patients");
      console.log("this.clinic.id:", this.id);
      console.log("this.patientCode:", this.patientCode);
      // Marge patient document
      await patientRef
        .where("clinic_id", "==", this.id)
        .where("patientNumber", "==", this.patientCode)
        .limit(1)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            console.log("Patients: empty");
            return null;
          } else {
            querySnapshot.forEach((doc) => {
              this.user_clinic = {
                clinic_id: this.id,
                clinic_name: this.clinic.name,
                patient_id: doc.id,
                patientNumber: this.patientCode,
                patient_name: this.user_name,
              };
            });
          }
        });
      console.log("user_clinic:", this.user_clinic);
      // Create patient document
      if (this.user_clinic != null) {
        await patientRef
          .doc(this.user_clinic.patient_id)
          .update({
            user_id: this.user.id,
            email: this.user.email,
            kana: "",
            name: this.user_name,
            phoneNumber: this.user.phone,
            status: true,
            is_self_regist: true,
            timestamp: Firebase.firestore.FieldValue.serverTimestamp(),
          })
          .then(() => {})
          .catch((error) => {
            console.error("Error update document: ", error);
          });
      } else {
        const patient = {
          clinic_id: this.clinic.id,
          clinic_name: this.clinic.name,
          user_id: this.user.id,
          email: this.user.email,
          kana: "",
          name: this.user_name,
          patientNumber: this.patientCode,
          phoneNumber: this.user.phone,
          status: true,
          is_self_regist: true,
        };

        await patientRef
          .add(patient)
          .then((doc) => {
            patient.id = doc.id;
            this.user_clinic = {
              clinic_id: this.clinic.id,
              clinic_name: this.clinic.name,
              patient_id: patient.id,
              patientNumber: this.patientCode,
              patient_name: this.user_name,
            };
          })
          .catch((error) => {
            console.error("Error adding document: ", error);
          });
      }

      // Add clinic and patient under User clinics array
      Firebase.firestore()
        .collection("Users")
        .doc(this.user_id)
        .update({
          clinics: Firebase.firestore.FieldValue.arrayUnion(this.user_clinic),
        });
      this.loading = false;
      this.$router.push({ name: "priscription_list", params: { id: this.id } });
    },
  },
};
</script>
